
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddMerchantModal from "@/views/merchants/AddMerchantModal.vue";
import FilterDropdown from "@/views/merchants/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiMerchant } from "@/core/api";
import moment from "moment";
import fileDownload from "js-file-download";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { formatDateTime, setModuleBCN } from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface Merchant {
  id: number;
  name: string;
  short_key: string;
  city: string;
  country_iso_2: string;
  created_at: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

interface SortOption {
  field: string;
  direction: string;
}

export default defineComponent({
  name: "merchants-index",
  components: {
    MBDatatable,
    AddMerchantModal,
    FilterDropdown,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const disabledExport = ref(false);
    const tableData = ref<Array<Merchant>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "status",
        value: "10",
      },
    ]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("merchants.name"),
        key: "name",
        sortable: true,
      },
      {
        name: t("merchants.country"),
        key: "country_iso_2",
        sortable: true,
      },
      {
        name: t("merchants.city"),
        key: "city",
        sortable: true,
      },
      {
        name: t("merchants.channelsQty"),
        key: "channels_qty",
        sortable: false,
        align: "right",
      },
      {
        name: t("merchants.creationTime"),
        key: "created_at",
        sortable: true,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    });

    const getCompanyList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiMerchant.getCompanyList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getCompanyList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getCompanyList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (
          val[item] != "" &&
          item != "country_iso_2" &&
          item != "lifecycle_status" &&
          item != "corporation_group_id" &&
          item != "channels_mgmt"
        ) {
          filterArr.push({
            field: item,
            value: val[item],
            condition: "like",
          });
        } else if (
          item == "country_iso_2" ||
          item == "lifecycle_status" ||
          item == "corporation_group_id" ||
          item == "channels_mgmt"
        ) {
          filterArr.push({
            field: item,
            value: val[item],
          });
        }
      }
      filterOptions.value.splice(1, filterOptions.value.length, ...filterArr);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(1, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getCompanyList, 1000);

    const fileExport = () => {
      disabledExport.value = true;
      ApiMerchant.merchantsPrint({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        download_data_type: "stream",
      })
        .then((data) => {
          disabledExport.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const bgColor = (e) => {
      let bgclass = "btn-light-primary";
      switch (e.toUpperCase()) {
        case "A":
        case "B":
        case "C":
        case "D":
        case "E":
        case "F":
          bgclass = "btn-light-primary";
          break;
        case "G":
        case "H":
        case "I":
        case "J":
        case "K":
        case "L":
          bgclass = "btn-light-warning";
          break;
        case "M":
        case "N":
        case "O":
        case "P":
        case "Q":
        case "R":
        case "S":
          bgclass = "btn-light-info";
          break;
        case "T":
        case "U":
        case "V":
        case "W":
        case "X":
        case "Y":
        case "Z":
          break;
      }
      return bgclass;
    };

    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    return {
      t,
      formatDateTime,
      loading,
      disabledExport,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      bgColor,
      getCompanyList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      handleFilter,
      handleFilterReset,
      updateList,
      fileExport,
      removeSearch,
    };
  },
});
